import React, { useMemo, useState } from 'react';
import ENV from '../env.json';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { AnimatedCheckmark } from './AnimatedCheckmark';

export const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [source, setSource] = useState('');
  const [consent, setConstent] = useState(false);

  const emailError = useMemo(() => {
    return email.indexOf('@gmail.com') !== -1;
  }, [email]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      await fetch(`${ENV.cloudFunctionsDomain}/mail-submitContactForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          topic,
          message,
          source,
          consent
        })
      });
      setIsSubmitting(false);
      setSubmitted(true);
    } catch (err) {
      setIsSubmitting(false);
      setSubmitted(false);
    }
  };

  if (submitted) {
    return (
      <div
        style={{ minHeight: '600px', display: 'grid', alignContent: 'center' }}
      >
        <div>
          <AnimatedCheckmark color="dark" style={{ margin: '0 auto' }} />
          <br />
          Thanks for getting in touch with us! Our team will reach out shortly.
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputLabel id="name-label">Your Name</InputLabel>
      <TextField
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="First and Last Name"
        id="name"
        type="text"
        fullWidth
        required
        margin="normal"
      />
      <br />
      <br />
      <InputLabel id="email-label">Your Work/Business Email</InputLabel>
      <TextField
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="name@yourcompany.com"
        error={emailError}
        helperText={
          emailError ? 'Please provide a business email address' : undefined
        }
        id="email"
        required
        type="email"
        margin="normal"
        fullWidth
      />
      <br />
      <br />
      <InputLabel id="topic-label">
        What kind of question do you have? *
      </InputLabel>
      <FormControl
        style={{
          width: '100%',
          marginTop: '13px',
          marginBottom: '8px'
        }}
      >
        <Select
          id="topic"
          name="topic"
          value={topic}
          onChange={(e) => setTopic(e.target.value as string)}
          required
          variant="outlined"
          placeholder="What kind of question do you have? *"
          fullWidth
        >
          <MenuItem value="PRE_SIGNUP">Pre-Signup Question</MenuItem>
          <MenuItem value="SALES">Sales / Pricing Question</MenuItem>
          <MenuItem value="DEMO">Request a demo</MenuItem>
          <MenuItem value="INTEGRATION">Integration Partnerships</MenuItem>
          <MenuItem value="SUPPORT">Technical Support</MenuItem>
          <MenuItem value="OTHER">Other</MenuItem>
        </Select>
      </FormControl>
      <br />
      <br />
      <InputLabel id="message-label">What can we help you with? *</InputLabel>
      <TextField
        type="text"
        multiline
        rows={7}
        label="Your Message"
        fullWidth
        required
        variant="outlined"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        id="name"
        margin="normal"
      />
      <br />
      <br />
      <InputLabel id="source-label">
        How'd you hear about us? (Optional)
      </InputLabel>
      <TextField
        variant="outlined"
        value={source}
        onChange={(e) => setSource(e.target.value)}
        placeholder="A blog post, podcast, referral, etc."
        id="source"
        type="text"
        margin="normal"
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={consent}
            color="primary"
            onChange={(e) => setConstent(e.target.checked)}
          />
        }
        label={
          <span style={{ fontSize: '16px', lineHeight: '14px' }}>
            I consent to having my contact data stored and used for
            communication purposes according to the{' '}
            <a
              href="/docs/privacy-policy"
              style={{ borderBottom: '1px solid' }}
            >
              site privacy policy
            </a>
            .
          </span>
        }
      />
      <Button
        variant="contained"
        color="primary"
        size="large"
        type="submit"
        disabled={
          !consent ||
          !name ||
          !email ||
          email.indexOf('@gmail.com') !== -1 ||
          !topic ||
          !message ||
          isSubmitting
        }
        style={{ marginTop: '12px' }}
      >
        {isSubmitting ? 'Sending message...' : 'Send message'}
      </Button>
    </form>
  );
};
